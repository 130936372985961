<template>
  <div class="wa__container">
    <main>
      <v-row class="h-inherit">
        <!-- <v-col cols="12" lg="6" class="position-relative d-none d-lg-block">
          <div
            class="auth__image"
            :style="{ backgroundImage: `url(${image})` }"
          >
            <div class="profile__image--label text-uppercase position-absolute">
              our super model:
            </div>
            <div class="profile__image--text position-absolute">
              elizabeth james
            </div>
          </div>
        </v-col> -->
        <!-- <v-col cols="12" lg="6"> -->
        <v-col cols="12">
          <div class="d-flex justify-space-between pa-2 pt-5">
            <BrandName />
            <v-chip label text-color="black" @click="$_changeLocalePage()">
              {{ $_getLocale() === "jp" ? "EN" : "JP" }}</v-chip
            >
          </div>
          <div class="wa__login px-3">
            <divider
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
              size="login"
            ></divider>
            <div class="wa__login__form mt-14">
              <LoginSection />
            </div>
          </div>
        </v-col>
      </v-row>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
  </div>
</template>
<script>
import LoginImage from "../assets/c1feb743391157.57ed335a8e3b4.jpg";
import BrandName from "../components/Global/Header/BrandName.vue";
import LoginSection from "../components/Global/Section/LoginSection.vue";

export default {
  components: {
    LoginSection,
    BrandName,
  },
  data: () => ({
    image: LoginImage,
  }),
};
</script>
<style scoped>
.wa__container {
  max-width: 100% !important;
  padding: 0 !important;
}
.wa__login {
  width: 100%;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  margin-top: 80px;
  text-align: center;
}
.wa__login__form {
  width: 25%;
  margin: 0 auto;
}
.h-inherit {
  min-height: inherit;
}
.auth__image {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.profile__image--text {
  bottom: 50px;
  left: 70px;
  font-size: 17px;
  letter-spacing: 3px;
  font-family: "Barlow-EL";
  color: var(--color-black);
}
.profile__image--label {
  font-family: "Montserrat-regular";
  font-size: 10px;
  color: var(--color-black);
  bottom: 70px;
  left: 70px;
}
/* media query */
@media screen and (max-width: 1264px) {
  .wa__login__form {
    width: 80%;
  }
}
@media screen and (max-width: 960px) {
  .wa__login__form {
    width: 90%;
  }
}
</style>
